<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container-fluid">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-6 lg:col-5">
              <div class="">
                <div class="flex align-items-center">
                  <div>
                    <Avatar
                      size="large"
                      class="primary"
                      icon="pi pi-user-edit"
                    />
                  </div>
                  <div class="ml-2">
                    <div class="text-2xl text-primary font-bold">
                      Edit User
                    </div>
                    <div class="text-sm text-500">
                      Modify user account details
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
    </template>
    <section class="page-section">
      <div class="container-fluid">
        <div class="grid">
          <div class="md:col-7 sm:col-12 comp-grid">
            <div class="">
              <div>
                <template v-if="!loading">
                  <div class="grid">
                    <div class="col-12">
                      <form
                        ref="observer"
                        tag="form"
                        @submit.prevent="startRecordUpdate()"
                      >
                        <!--[form-content-start]-->
                        <div class="grid">
                          <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">Name</div>
                            <div id="ctrl-name-holder">
                              <InputText
                                ref="ctrlname"
                                v-model.trim="formData.name"
                                label="Name"
                                type="text"
                                placeholder="Enter Name"
                                class="w-full"
                                pattern="^[0-9A-Za-z-_@.]+$"
                                :class="getErrorClass('name')"
                              >
                              </InputText>
                            </div>
                          </div>
                          <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">Username *</div>
                            <div id="ctrl-username-holder">
                              <check-duplicate
                                v-model="formData.username"
                                check-path="components_data/administrators_username_exist/"
                                v-slot="checker"
                              >
                                <InputText
                                  :disabled="true"
                                  ref="ctrlusername"
                                  @blur="checker.check"
                                  :loading="checker.loading"
                                  v-model.trim="formData.username"
                                  label="Username"
                                  type="text"
                                  placeholder="Enter Username"
                                  class="w-full"
                                  pattern="^[0-9A-Za-z-_@.]+$"
                                  :class="getErrorClass('username')"
                                >
                                </InputText>
                                <small
                                  v-if="!checker.loading && checker.exist"
                                  class="p-error"
                                >
                                  Username has already been used</small
                                >
                                <small v-if="checker.loading" class="text-500"
                                  >Checking...</small
                                >
                              </check-duplicate>
                            </div>
                          </div>
                          <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">Email *</div>
                            <div id="ctrl-email-holder">
                              <check-duplicate
                                v-model="formData.email"
                                check-path="components_data/administrators_email_exist/"
                                v-slot="checker"
                              >
                                <InputText
                                  ref="ctrlemail"
                                  @blur="checker.check"
                                  :loading="checker.loading"
                                  v-model.trim="formData.email"
                                  label="Email"
                                  type="email"
                                  placeholder="Enter Email"
                                  class="w-full"
                                  maxLength="40"
                                  :class="getErrorClass('email')"
                                >
                                </InputText>
                                <small
                                  v-if="!checker.loading && checker.exist"
                                  class="p-error"
                                >
                                  Email has already been used</small
                                >
                                <small v-if="checker.loading" class="text-500"
                                  >Checking...</small
                                >
                              </check-duplicate>
                            </div>
                          </div>
                          <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">
                              Ghana Card Number *
                            </div>
                            <div class="">
                              <InputMask
                                ref="ctrlghana_card"
                                label="Ghana Card Number"
                                type="text"
                                inputClass="w-full"
                                placeholder="GHA-000000000-0"
                                v-model.trim="formData.ghana_card"
                                class="w-full"
                                mask="aaa-999999999-9"
                                style="text-transform: uppercase"
                                :class="getErrorClass('ghana_card')"
                              >
                              </InputMask>
                            </div>
                          </div>
                          <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">Account Type *</div>
                            <div id="ctrl-user_type-holder">
                              <Dropdown
                                class="w-full"
                                :class="getErrorClass('user_type')"
                                optionLabel="label"
                                optionValue="value"
                                ref="ctrluser_type"
                                v-model="formData.user_type"
                                :options="$menus.account_typeItems"
                                label="Account Type"
                                placeholder="Select ..."
                              >
                              </Dropdown>
                            </div>
                          </div>
                          <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">
                              Account Status *
                            </div>
                            <div id="ctrl-account_status-holder">
                              <Dropdown
                                class="w-full"
                                :class="getErrorClass('account_status')"
                                optionLabel="label"
                                optionValue="value"
                                ref="ctrlaccount_status"
                                v-model="formData.account_status"
                                :options="$menus.account_statusItems"
                                label="Account Status"
                                placeholder="Select ..."
                              >
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                        <!--[form-content-end]-->
                        <div v-if="showSubmitButton" class="text-center my-3">
                          <Button
                            type="submit"
                            label="Update"
                            icon="pi pi-send"
                            :loading="saving"
                          />
                        </div>
                      </form>
                      <slot :submit="submit" :saving="saving"></slot>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="p-3 text-center">
                    <ProgressSpinner style="width: 50px; height: 50px" />
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
//[--PAGE-IMPORT-STATEMENT--]
import { PageMixin } from "../../mixins/page.js";
import { EditPageMixin } from "../../mixins/editpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import {
  required,
  email,
  sameAs,
  minLength,
  maxLength,
  minValue,
  maxValue,
  numeric,
  integer,
  decimal,
  ipAddress,
} from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "editAdministratorsPage",
  components: {
    //[--PAGE-COMPONENT-NAME--]
  },
  mixins: [PageMixin, EditPageMixin],
  props: {
    pageName: {
      type: String,
      default: "administrators",
    },
    idName: {
      type: String,
      default: "administrator_id",
    },
    routeName: {
      type: String,
      default: "administratorsedit",
    },
    pagePath: {
      type: String,
      default: "administrators/edit",
    },
    apiPath: {
      type: String,
      default: "administrators/edit",
    },
  },
  data() {
    return {
      formData: {
        username: "",
        email: "",
        name: "",
        account_status: "",
        ghana_card: "",
        user_type: "",
      },
      submitted: false,
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return "Edit Administrators";
      },
    },
    currentRecord: {
      get: function () {
        return this.$store.getters["administrators/currentRecord"];
      },
      set: function (value) {
        this.$store.commit("administrators/setCurrentRecord", value);
      },
    },
  },
  validations() {
    let formData = {
      username: { required },
      email: { required, email },
      name: {},
      account_status: { required },
      ghana_card: { required },
      user_type: { required },
    };
    return { formData };
  },
  methods: {
    ...mapActions("administrators", ["updateRecord", "fetchRecord"]),
    async startRecordUpdate() {
      this.submitted = true;
      const isFormValid = !this.v$.$invalid;
      if (!isFormValid) {
        this.flashMsg("Please complete the form", "Form is invalid", "error");
        return;
      }
      this.saving = true;
      let id = this.id;
      let url = this.apiUrl;
      let payload = this.normalizedFormData();
      let data = { id, url, payload };
      this.updateRecord(data).then(
        (response) => {
          this.saving = false;
          this.flashMsg(this.msgAfterUpdate);
          this.resetForm();
          this.closeDialogs(); // close page dialog that if opened
          if (this.redirect) this.navigateTo(`/administrators`);
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    updateFormFields: function () {
      //update form fields value after load from api
      //e.g convert fieldvalue (value,value2,value2) to array
    },
    resetForm() {
      //reset form fields value
      this.formData = { name: "", username: "", email: "", account_status: "" };
      //raise event to reset other custom form components
      //this.$EventBus.$emit("resetForm");
    },
    getErrorClass(field) {
      if (this.v$.formData[field].$invalid && this.submitted) {
        return "p-invalid";
      }
      return "";
    },
  },
  watch: {},
  async mounted() {},
  async created() {},
};
</script>
<style scoped></style>
<style scoped></style>
